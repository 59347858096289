html {
  body {
    font-family: 'Oswald';font-size: 22px;
}
  margin: 0px;
  /* background-color: #0a072a; */
}

.App {
margin: 0px;
text-align: center;
/* background-color: #0a072a; */
  
}

.App-header {
  display: flex;
  align-items: center; /* Align items vertically */
  justify-content: center; /* Align items horizontally */
  padding: 10px; /* Adjust padding as needed */
  margin: 0px;
  background-color: #000a36;
  
}

.logo {
  
  width: auto;
  max-width: 250px; /* Ensure the logo does not exceed its container width */
  height: auto;
  max-height: 150px; /* Adjust maximum height as needed */
}

.App-body {
  margin: 0px;
  /* background-color: #0a072a; */
  /* display: flex; */
  align-items: center;
  justify-content: center;
}


.App-footer {
  display: flex;
  flex-direction: column;
  
  background-color: #FFF5DB;
  
}

.App-footer img {
  width: auto;
  max-width: 20px; /* Ensure the logo does not exceed its container width */
  height: auto;
  max-height: 20px; 
}

.App-footer p {
  font-size: 12px;
  margin: 0px;
  padding-top: 10px;
  color: #000a36;
  
  /* animation: slide 30s ease forwards infinite; */
}

@keyframes slide {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }

}


.footer-link {
  padding-bottom: 20px;
  padding-top: 10px;
  font-size: 10px;
  color: #F71254;
}
