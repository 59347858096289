.video-container {
  margin: 0px;
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio (9 / 16 * 100%) */
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


@media screen and (max-width: 768px) {
  .video-container {
    padding-top: 75%; /* Adjust aspect ratio for smaller screens */
  }
}
